// @flow
import {useCallback, useEffect, useRef, useState} from "react";

export default function useOpenWindow(urlPath) {
    const childWindowRef = useRef(null);
    const [isClosed, setClosed] = useState<boolean>(false);
    const shouldOpenAutomatically = useRef<boolean>(true);
    const timer = useRef(null);

    const openWindow = useCallback((url = urlPath) => {
        setClosed(false);
        shouldOpenAutomatically.current = true;
        if (childWindowRef.current && !childWindowRef.current.closed) {
            childWindowRef.current.location.href = url
            setTimeout(() => {
                childWindowRef.current.focus();
            }, 500)
        } else {
            childWindowRef.current = window.open(
                url,
                "childWindow",
                "width=1600,height=500"
            );

            timer.current = setInterval(function () {
                if (childWindowRef.current?.closed) {
                    clearInterval(timer.current);
                    setClosed(true)
                    shouldOpenAutomatically.current = false;
                }
            }, 500);
        }
    }, [urlPath]);

    useEffect(() => {
        return () => {
            if (timer.current) clearInterval(timer.current);
        }
    }, [])

    return {
        openWindow,
        isClosed
    }
}