// @flow
import React, {useCallback, useEffect, useState} from "react";
import {useTablePaginator} from "../Pay/useTablePaginator";
import {Bill} from "../../components/BillContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import DocumentService from "../../services/DocumentService";
import dateFormat from "dateformat";
import {getSepaFileDownloads, getSepaFiles, getSepaXmlFile} from "../../services/API/sepafiles.service";
import '../Pay/pay_table.css';
import InView from "../../components/InView";

function SepaPage() {
    const [, hasError, filesPaginated, hasMore, total, , nextPage] = useTablePaginator(getSepaFiles);
    const [files, setFiles] = useState<Array<Bill>>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [downloadRecords, setDownloadRecords] = useState<[]>([]);

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage()
    }, [hasMore, hasError, nextPage]);


    const handleCloseModal = () => {
        setModalOpen(false);
    };


    useEffect(() => {
        setFiles(filesPaginated)
    }, [filesPaginated])

    const downloadSepaXmlFile = useCallback(async (id) => {
        try {
            const {status, data} = await getSepaXmlFile(id);
            const {data: dataDownloads} = await getSepaFileDownloads(id)

            if (status === 200) {
                setFiles(prev => prev.map(file => file.id === id ? {
                    ...file,
                    downloads_count: data.downloads_counter,
                    last_download_date: dataDownloads._embedded?.records[0]?.download_date,
                    last_download_by_user: dataDownloads._embedded?.records[0]?.created_by_user
                } : file))
            }
            if (data.file) {
                DocumentService.requestDownload(data.file, data.filename, 'application/xml')
            }
        } catch (e) {
            console.log(e)
        }
    }, [])


    const getFileDownloads = useCallback(async (id) => {
        try {
            const {data} = await getSepaFileDownloads(id)
            setDownloadRecords(data?._embedded?.records);
            setModalOpen(true);
        } catch (e) {

        }
    }, [])


    return (<>
        <Modal className={"ModalStyle"} show={isModalOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Downloaded by</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className={'payment-page'} style={{
                    width: 'calc(100% - 2rem)',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '1rem',
                    maxHeight: '70vh',
                    border: '1px solid rgba(0,0,0,.12)',
                    borderRadius: '11px',
                    overflow: 'auto'
                }}>

                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Downloaded from</th>
                            <th>Download on</th>
                        </tr>
                        </thead>
                        <tbody>
                        {downloadRecords.map((record, index) => {
                            return <tr key={record.sepa_file_id}>
                                <td>{index + 1}</td>
                                <td>{record.created_by_user || '-'}</td>
                                <td>{record.download_date?.date ? dateFormat(record.download_date.date, 'dd.mm.yyyy h:mm TT') : '-'}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <div className={'table-footer'}>
                        <div className={'table-footer-inner'}>{downloadRecords.length} Results</div>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>


        <div className={"payment-page"}>
            <h1>Sepa payment file</h1>
            <div style={{
                display: 'flex', gap: '1rem'
            }}>
                <Link to="/bills">Back to Home</Link>
                <Link to="/pay">Pay Bills</Link>
            </div>
            <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '80vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto',
                cursor: 'pointer'
            }}>

                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>File</th>
                        <th>Tenant</th>
                        <th>Total bills</th>
                        <th>Total payed</th>
                        <th>Is abroad</th>
                        <th>Created by</th>
                        <th>Created at</th>
                        <th>Downloaded from</th>
                        <th>Download on</th>
                        <th>Downloads</th>
                    </tr>
                    </thead>
                    <tbody>
                    {files.length === 0 ? <tr style={{padding: '1rem'}}>
                            <td colSpan={7}>No results</td>
                        </tr> :
                        files.map((file, index) => {
                            return index !== Math.round(files.length / 1.2) ? <tr key={file.file_name}>
                                <td>{index + 1}</td>
                                <td className='with-icon with-action' onClick={downloadSepaXmlFile.bind(this, file.id)}>
                                    <span>{`schaden_${file.mandnr}_${file.date_created.date.split(' ')[0].replace(/-/g, '_')}_${file.foreign_currency}`}</span>
                                </td>
                                <td>{file.mandnr}</td>
                                <td>{file.bills_count}</td>
                                <td>{file.total_for_paying} {file.foreign_currency}</td>
                                <td>{file.currency_abroad===1 ? 'Yes' : 'No'}</td>
                                <td>{file.created_by_user || '-'}</td>
                                <td>{file.date_created?.date ? dateFormat(file.date_created.date, 'dd.mm.yyyy') : '-'}</td>
                                <td onClick={getFileDownloads.bind(this, file.id)}>{file.last_download_by_user}</td>
                                <td>{file.last_download_date?.date ? dateFormat(file.last_download_date?.date,'dd.mm.yyyy' ): '-'}</td>
                                <td className={'with-action'}
                                    onClick={getFileDownloads.bind(this, file.id)}>{file.downloads_count || 0}</td>
                            </tr> : <InView as={'tr'} key={file.file_name} onceOnIntersect={onInView}>
                                <td>{index + 1}</td>
                                <td className='with-icon with-action' onClick={downloadSepaXmlFile.bind(this, file.id)}>
                                    <span>{`schaden_${file.mandnr}_${file.date_created.date.split(' ')[0].replace(/-/g, '_')}_${file.foreign_currency}`}</span>
                                </td>
                                <td>{file.mandnr}</td>
                                <td>{file.bills_count}</td>
                                <td>{file.total_for_paying}</td>
                                <td>{file.created_by_user || '-'}</td>
                                <td>{file.date_created?.date ? dateFormat(file.date_created.date, 'dd.mm.yyyy') : '-'}</td>
                                <td onClick={getFileDownloads.bind(this, file.id)}>{file.last_download_by_user}</td>
                                <td>{file.last_download_date?.date ? dateFormat(file.last_download_date?.date,'dd.mm.yyyy' ): '-'}</td>
                                <td className={'with-action'}
                                    onClick={getFileDownloads.bind(this, file.id)}>{file.downloads_count || 0}</td>
                            </InView>
                        })}
                    </tbody>
                </table>
                <div className={'table-footer'}>
                    <div className={'table-footer-inner'}>Results 1 - {filesPaginated.length} of {total}</div>
                </div>
            </div>
        </div>
    </>)
}

export default SepaPage;